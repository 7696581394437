import parsePrices from './parseSWPrices';

export function getProductUrl(product) {
	if (product.seoUrls && product.seoUrls.length > 0) {
		return '/' + product.seoUrls[0].seoPathInfo;
	} else {
		return '/detail/' + product.id;
	}
}

export function getProductPrices(product) {
	let prices =
		product.calculatedPrices && product.calculatedPrices.length > 0
			? product.calculatedPrices[0]
			: product.calculatedPrice;
	return parsePrices(prices);
}

export function getVariants(product) {
	let childCount = product.translated.customFields?.variant_active_count || 0;

	return childCount > 1 && product.parentId !== null && product.options?.length > 0 ? product.options.map((option) => option.translated.name).join(' / ') : false;
}

export function hasVariants(product) {
	return product.parentId !== null;
}

export function getIsAvailable(product) {
	let sortableSaleable = product?.translated?.customFields?.sortable_saleable || false;

	return product.available || sortableSaleable;
}
export function calculateProductWidth(products) {
	if (products.length >= 8) {
		return { xs: 6, sm: 4, md: 3, lg: 3, xl: 3 };
	} else {
		let gridWidth = products.length > 4 ? Math.ceil(products.length / 2) : products.length;
		let gridWidthMD = products.length > 3 ? Math.floor(products.length / 2) : products.length;

		return {
			xs: 6,
			sm: Math.min(12 / gridWidthMD, 6),
			md: Math.min(12 / gridWidth, 6),
			lg: Math.min(12 / gridWidth, 6),
			xl: Math.min(12 / gridWidth, 6)
		};
	}
}

export function getProductFamily(product) {
	let productFamilyAttribute = product?.properties?.filter((property) => {
		if(property.group?.translated?.customFields?.attributeCode === 'product_family') {
			return property;
		} else {
			return false;
		}
	});

	if(productFamilyAttribute && productFamilyAttribute.length > 0) {
		return productFamilyAttribute[0].translated?.name;
	} else {
		return false;
	}
}

export function getCategoryBreadcrumb(breadcrumbs, skip = 0) {
	let keys = Object.keys(breadcrumbs);

	if(skip > 0) {
		keys.splice(0, skip);
	}

	let breadcrumbArray = [];

	keys.forEach((key, index) => {
		breadcrumbArray[index] = breadcrumbs[key].name;
	});

	return breadcrumbArray;
}

export function getMainProduct(product, configurator) {
	let splitGroups = product?.translated?.customFields?.variant_split_group_ids || [];

	if(!product.options || splitGroups.length >= product.options.length) {
		return product;
	}

	let mainProductOptions = product.options.filter((option) => {
		return splitGroups.indexOf(option.groupId) >= 0;
	}).map((option) => option.id);

	configurator.forEach((group) => {
		if(splitGroups.indexOf(group.id) < 0 && group.options.length > 0) {
			mainProductOptions.push(group.options[0].id);
		}
	});

	let variantData = product?.translated?.customFields?.variant_data;
	let mainVariant = variantData.filter((variant) => {
		let isMain = true;

		variant.options.forEach((variantOption) => {
			if(mainProductOptions.indexOf(variantOption.id) < 0) {
				isMain = false;
			}
		});

		return isMain;
	});

	return mainVariant.length > 0 ? mainVariant[0] : product;
}

export function getProductMetaDescription({product, prices, translation, formatPrice}) {
	let description = product.translated.metaDescription || product.translated.description || '';
	let descriptionText = description.replace(/<[^>]+>/g, '');

	if(!descriptionText || descriptionText.length < 100) {
		let productName = product.translated.name;
		let category = product.seoCategory?.breadcrumb[2].toLowerCase() || false;
		let price = prices.price;

		if(productName && category && price) {
			return translation('meta-description-template', {
				category,
				productName,
				price: formatPrice(price)
			});
		}
	}

	return descriptionText;
}

import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Badge } from '@material-ui/core';
import Like from '../../public/images/icons/heart-ultrathin.svg';
import theme from '../theme';
import { useTranslation } from 'tools/i18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import NewportDrawer from '../drawer';
import useUserStore from '../../services/stores/UserStore';
import StorefrontApiService from '../../services/StorefrontApiService';
import FavoriteItem from '../products/wishlist/FavoriteItem';
import CallToActionButton from '../products/partials/CallToActionButton';
import CreateWishlist from '../products/wishlist/CreateWishlist';
import LoginSignupButtons from './account/LoginSignupButtons';
import Ornament from '../decoration/Ornament';
import AddToWeddingList from '../products/wishlist/AddToWeddingList';
import useCartStore from '@services/stores/CartStore';

const styles = {
    wrapper: {
        display: 'flex',
    },
    trigger: {
        cursor: 'pointer',
        padding: '10px 7px',
        height: 40,
        background: 'transparent',
        border: 'none',
    },
    icon: {
        display: 'block',
        width: 20,
        height: 20,
        color: '#000000',
        strokeWidth: 0.5,
        fill: 'transparent',
        position: 'relative',
    },
    badge: {
        verticalAlign: 'initial',
    },
    count: {
        color: theme.palette.primary.background,
        padding: '0 4px',
        fontSize: 11,
        backgroundColor: '#000',
        position: 'absolute',
        top: 0,
        right: -1,
        '.xmas &': {
            background: theme.palette.primary.background,
            color: '#000',
        },
    },
    drawerTitle: {
        fontSize: '14px',
        fontWeight: 600,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: 280,
        width: '100%',
    },
    loginWrapper: {
        padding: '3rem 2rem',
        background: '#fafafa',
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '10px 0',
        marginTop: 3,
        fontSize: 14,
        '&:hover': {
            backgroundColor: '#fafafa',
        },
        '& .name': {
            lineHeight: '22px',
            color: 'inherit',
        },
        '& .count': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '8px',
            marginRight: '8px',
            fontWeight: 500,
        },
        '& .title': {
            display: 'flex',
            '.xmas &': {
                color: '#000 !important',
            },
        },
        '&.active': {
            fontWeight: 600,
        },
        '&.new': {
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            fontSize: 12,
            backgroundColor: theme.palette.primary.main + '!important',
            color: theme.palette.primary.background,
        },
        '&.empty': {
            flexDirection: 'column',
            color: '#555',
            textAlign: 'center',
            justifyContent: 'center',
            '&:hover': {
                background: 'unset',
            },
        },
        '& a': {
            color: 'inherit',
        },
    },
    active: {
        '& .name': {
            fontWeight: 500,
        },
    },
    activeIcon: {
        marginRight: 5,
        fontSize: '1rem',
        cursor: 'pointer',
    },
    actionWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
        marginTop: 10,
        paddingTop: '20px',
        '& > span ': {
            margin: 'auto',
        },
        '& > span a': {
            color: 'inherit',
            fontSize: 12,
            marginRight: 0,
        },
    },
    tabsGroup: {
        width: '100%',
        margin: 'auto auto 20px',
        display: 'flex',
        justifyContent: 'center',
    },
    tabButton: {
        margin: 0,
        background: 'transparent',
        border: 'none',
        borderBottom: '2px solid transparent',
        padding: '15px 0 10px',
        fontWeight: 500,
        fontFamily: 'Futura',
        fontSize: '16px',
        width: 'auto',
        flexGrow: 1,
        cursor: 'pointer',
        '&:hover': {
            borderBottom: '2px solid #222',
        },
        '&.active': {
            borderBottom: '2px solid #000',
        },
    },
    wishlistFavoriteChoice: {
        padding: '1rem',
        background: '#f5f5f5',
        width: '100%',
        textAlign: 'center',
    },
};

const useStyles = makeStyles(() => styles, { name: 'Favorites' });

export default function Favorites() {
    const router = useRouter();
    const classes = useStyles();
    const { t } = useTranslation();
    const [ open, setOpen ] = useState(false);
    const [ activeTab, setActiveTab ] = useState(0);
    const [ highlighted, setHighlighted ] = useState(0);
    const [ wishlistType, setWishlistType ] = useState('standard');
    const [ wishlistProductPickToggle, setWishlistProductPickToggle ] = useState(false);
    const [ showMoveFavoritesToWishlist, setShowMoveFavoritesToWishlist ] = useState(false);
    const [ favoriteProducts, setFavoriteProducts ] = useState([]);

    const cartMethods = useCartStore((state) => state.methods);
    const user = useUserStore();
    const myNewportService = useMemo(() => user.getService(), [ user.isLoggedIn ]);
    const wishlists = useUserStore((state) => state.wishlists);
    const weddingList = useUserStore((state) => state.weddingList);
    const wishlistProducts = useUserStore((state) => state.wishlistProducts);
    const favorites = useUserStore((state) => state.favorites);
    const isLoggedIn = useUserStore((state) => state.isLoggedIn);

    useEffect(async () => {
        let path = router.asPath;
        if (path?.includes('#create-wishlist')) {
            if (path.includes('#create-wishlist-xmas')) {
                setWishlistType('christmas');
            }

            setOpen(true);
            toggleTab(1);
        } else {
            setOpen(false);
        }
    }, [ router.asPath ]);

    useEffect(() => {
        setHighlighted(wishlistProducts.length + favoriteProducts.length);
    }, [ wishlistProducts, favoriteProducts ]);

    useEffect(async () => {
        if (favorites.length) {
            const api = new StorefrontApiService();
            const response = await api.getProductByIds(favorites);

            if (response.status === 'success' && Array.isArray(response?.products)) {
                setFavoriteProducts(response?.products || []);
            }
        } else {
            setFavoriteProducts([]);
        }
    }, [ favorites ]);

    useEffect(() => {
        window.addEventListener('toggle-wishlist-product', handleToggleWishlistProduct);

        return () => {
            window.removeEventListener('toggle-wishlist-product', handleToggleWishlistProduct);
        };
    }, []);

    async function handleToggleWishlistProduct(event) {
        const {
            detail: {
                productId,
            },
        } = event;

        toggleDrawer();
        toggleTab(1);

        const api = new StorefrontApiService();
        const response = await api.getProductByIds([ productId ]);

        if (response.status === 'success' && response.products.length === 1) {
            setWishlistProductPickToggle(response.products[0]);
        }
    }

    function toggleDrawer() {
        if (!isLoggedIn) {
            toggleTab(0);
        }

        if (isLoggedIn && favorites.length === 0) {
            toggleTab(1);
        }

        if (open) {
            const currentProductId = wishlistProductPickToggle.id;
            if (!wishlistProducts.includes(currentProductId)) {
                user.favoriteMethods.addToFavorites(currentProductId);
            }

            setShowMoveFavoritesToWishlist(false);
            setWishlistProductPickToggle(false);
        }

        setOpen(!open);
    }

    function toggleTab(target) {
        setActiveTab(target);
    }

    function triggerClick(event) {
        event.preventDefault();
        event.stopPropagation();
        toggleDrawer();
        return false;
    }

    function renderFavorites() {
        async function handleAllAddToCart() {
            setOpen(false);
            await cartMethods.addItemsToCart(favorites);
        }

        function handleMoveToWishlist() {
            toggleTab(1);
            setShowMoveFavoritesToWishlist(true);
        }

        return <>
            <h3 className={classes.drawerTitle}>{t('favorite-wishlist-drawer-title')}</h3>

            {favoriteProducts.map((product) => {
                return (
                    <FavoriteItem key={product.id} product={product}></FavoriteItem>
                );
            })}

            {favoriteProducts.length === 0 && (
                <article className={[ classes.item, 'empty' ].join(' ')}>
                    <p>{t('no-favorites')}</p>
                </article>
            )}

            {favoriteProducts.length > 0 &&
                <div key={'key-actions'} className={[ classes.actionWrapper ].join(' ')}>
                    <CallToActionButton
                        size={'small'}
                        colorScheme={'bg-light'}
                        onClick={handleAllAddToCart}
                    >
                        {t('add-all-favorites-to-cart')}
                    </CallToActionButton>

                    <CallToActionButton
                        size={'small'}
                        colorScheme={'bg-light'}
                        onClick={handleMoveToWishlist}
                        show={isLoggedIn}
                    >
                        {t('new-wishlist-from-favorites')}
                    </CallToActionButton>
                    <CallToActionButton
                        style={{ marginTop: '4rem' }}
                        size={'small'}
                        colorScheme={'bg-white'}
                        onClick={() => user.favoriteMethods.clearFavorites()}
                    >
                        {t('favorite-clear-list')}
                    </CallToActionButton>
                </div>
            }
        </>;
    }

    function renderCurrentProductChoice(product) {
        if (!product) {
            return null;
        }

        return <>
            <b style={{ marginBottom: '8px' }}>{t('add-to-wishlist-product-notice')}</b>
            <div style={{ width: '100%' }}>
                <FavoriteItem
                    style={{ background: '#f5f5f5', padding: '1rem' }}
                    product={product}
                    showBuyButton={false}
                ></FavoriteItem>
            </div>
            <Ornament style={{width: '100%'}} ornamentStyle={'wedding-top'}>
                <AddToWeddingList productId={product.id} colorScheme={'bg-wedding-fresh'}></AddToWeddingList>
            </Ornament>
        </>;
    }

    function renderMoveToWishlist(show) {
        if (!show) {
            return null;
        }

        return <div className={classes.wishlistFavoriteChoice}>
            {t('add-favorites-to-wishlist')}
        </div>;
    }

    function renderWishlists() {
        if (!isLoggedIn) {
            return <article className={[ classes.item, 'empty' ].join(' ')}>
                <div className={classes.loginWrapper}>
                    <LoginSignupButtons></LoginSignupButtons>
                </div>
            </article>;
        }

        return (
            <>
                {renderCurrentProductChoice(wishlistProductPickToggle)}
                {renderMoveToWishlist(showMoveFavoritesToWishlist)}

                {wishlists.map((wishlist, index) => {
                    let itemCount = wishlist?.items?.length ? wishlist?.items?.length : wishlist.items_count || 0;

                    return (
                        <article key={index + '-' + wishlist.id} className={[ classes.item ].join(' ')}>
                            <Link href={myNewportService.createLink(`/account/lists/${wishlist.id}`)}>
                                <a className={'name'}>{wishlist.name}</a>
                            </Link>
                            <span className={'count'}>({itemCount})</span>
                            <CallToActionButton
                                show={!!wishlistProductPickToggle}
                                style={{
                                    minWidth: '100px',
                                    maxWidth: 'fit-content',
                                    marginLeft: 'auto',
                                    maxHeight: '46px',
                                }}
                                colorScheme={'bg-light'}
                                size={'small'}
                                onClick={() => user.addFavorite(wishlistProductPickToggle.id, wishlist.id)}
                            >
                                {user.wishlistMethods.existsInWishlist(wishlistProductPickToggle.id, wishlist.id) ? t('wishlist-remove-item') : t('wishlist-add-item')}
                            </CallToActionButton>
                            <CallToActionButton
                                show={!!showMoveFavoritesToWishlist}
                                style={{
                                    minWidth: '100px',
                                    maxWidth: 'fit-content',
                                    marginLeft: 'auto',
                                    maxHeight: '46px',
                                }}
                                colorScheme={'bg-light'}
                                size={'small'}
                                onClick={async () => {
                                    setShowMoveFavoritesToWishlist(false);
                                    await user.favoriteMethods.moveToList(favorites, wishlist.id);
                                    user.favoriteMethods.clearFavorites();
                                }}
                            >
                                {user.wishlistMethods.existsInWishlist(wishlistProductPickToggle.id, wishlist.id) ? t('wishlist-remove-item') : t('wishlist-add-item')}
                            </CallToActionButton>
                        </article>
                    );
                })}

                {wishlists.length === 0 && (
                    <article className={[ classes.item, 'empty' ].join(' ')}>
                        <div>{t('no-wishlist')}.</div>
                    </article>
                )}

                <div key={'key-actions'} className={[ classes.actionWrapper ].join(' ')}>
                    <CreateWishlist type={wishlistType}></CreateWishlist>
                </div>
            </>
        );
    }

    function renderTabsContent() {
        return (
            <div className={[ classes.list ].join(' ')}>
                <div className={classes.tabsGroup}>
                    <button
                        className={[ classes.tabButton, activeTab === 0 ? 'active' : '' ].join(' ')}
                        onClick={() => {
                            toggleTab(0);
                        }}
                    >
                        {t('favorite-wishlist-drawer-tab-one-title')}
                        <Badge style={{ marginLeft: '18px' }} color={'primary'}
                               badgeContent={favorites.length || 0}></Badge>
                    </button>
                    <button
                        className={[ classes.tabButton, activeTab === 1 ? 'active' : '' ].join(' ')}
                        onClick={() => {
                            toggleTab(1);
                        }}
                    >
                        {t('favorite-wishlist-drawer-tab-two-title')}
                        <Badge style={{ marginLeft: '18px' }} color={'primary'}
                               badgeContent={wishlists.length || 0}></Badge>
                    </button>
                </div>

                {activeTab === 0 && renderFavorites()}
                {activeTab === 1 && renderWishlists()}
            </div>
        );
    }

    function renderWeddingList() {
        let link = '/wedding';
        const hasWeddingList = weddingList?.id;

        if (wishlistProductPickToggle) {
            return null;
        }

        if (hasWeddingList) {
            link = myNewportService.createLink(`/account/wedding-list`);
        }

        return <Ornament ornamentStyle={'wedding-top'}>
            <CallToActionButton
                addLines={true}
                style={{ marginBottom: '8px' }}
                colorScheme={'bg-wedding-fresh'}
                href={link}
                onClick={() => toggleDrawer()}
            >
                {hasWeddingList ? t('favorite-wishlist-drawer-wedding-list-button') : t('wedding-list-create')}
            </CallToActionButton>
        </Ornament>;
    }

    return (
        <>
            <div className={[ classes.wrapper, theme.mode ].join(' ')}>
                <button className={[ classes.trigger, 'favorites' ].join(' ')} onClick={e => {
                    triggerClick(e);
                }}>
                    <Badge badgeContent={(highlighted)} classes={{ root: classes.badge, badge: classes.count }}>
                        <Like width={20} className={classes.icon} />
                    </Badge>
                </button>

                <NewportDrawer anchor="right" open={open} close={toggleDrawer}>
                    {renderWeddingList()}
                    {renderTabsContent()}
                </NewportDrawer>
            </div>
        </>
    );
}

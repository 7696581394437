import {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/styles';
import {Container, Grid, Typography} from "@material-ui/core";
import ProductItem from "../../products/item";
import {useTranslation} from "../../../tools/i18n";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../theme";
import {useRouter} from "next/router";
import urlNormalizer from "../../../tools/urlNormalization";
import Image from "next/image";
import { elevateCart, elevateClickNotification } from "tools/elevateClient";
import useCartStore from '@services/stores/CartStore';
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles(theme => ({
	wrapper: {
		padding: '20px 0 20px',
		width: '100%',
		borderRadius: '3px 0 0 3px',
		backgroundColor: theme.palette.primary.background,
		color: theme.palette.primary.color,

	},
	title: {
        marginBottom: 10,
	    fontFamily: theme.typography.fontFamily,
	    fontSize: 14,
        fontWeight: 600,
		'.xmas &': {
			color: '#222 !important',
		},
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: 10
        }
	},
    showMore: {
	    minWidth: 200,
        marginTop: 20,
        padding: '10px 24px',
        backgroundColor: theme.palette.primary.color,
        fontSize: 11,
        color: theme.palette.primary.background,
        fontWeight: 600,
        textTransform: 'none',
        '&:hover': {
	        backgroundColor: theme.palette.primary.color
        }
    },
    itemLink: {
	    minWidth: 0,
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important'
    },
    itemImageWrapper: {
	    '&::after': {
	        display: 'block',
            bottom: 0,
            content: '""',
            left: 0,
            mixBlendMode: 'darken',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 5,
            backgroundColor: '#f9f9f9',
        }
    },
    itemInfo: {
	    padding: '8px 0 0 0',
		'.xmas &': {
			color: '#000 !important',
		},
    },
    itemName: {
	    maxWidth: '100%',
        marginBottom: 8,
	    fontSize: '12px !important',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    itemPrice: {
	    fontSize: '12px !important'
    },
    itemDiscountBubble: {
		zIndex: 10,
        fontSize: 11
    },
    itemPurchaseButton: {
        marginTop: 5,
		'.xmas &': {
			backgroundColor: theme?.palette?.primary.main,
			'&:hover': {
				backgroundColor: theme?.palette?.primary.hover,
			},
		},
    }
}), {name: 'ElevateUpsell'});

export default function ElevateUpsell() {
    const classes = useStyles();
    const {elevateMarket, language} = useSessionStore((state) => state.session.config);
	const cart = useCartStore((state) => state.cart);
    const router = useRouter();
	const [products, setProducts] = useState([]);
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const {t} = useTranslation();

    useEffect(async () => {
        let productIds = cart.lineItems.filter((item) => item.type !== 'promotion').map((item) => item.payload.productNumber);

        if(elevateMarket && productIds.length > 0) {
            let cartResults = await elevateCart(
                productIds,
                elevateMarket,
			    language.languageCode,
                isMobile ? 'mobile' : 'desktop'
            )

            let products = [];
			if (cartResults?.recommendationLists?.length) {
				cartResults?.recommendationLists[0]?.productGroups.forEach((productGroup) => {
					products.push(productGroup.products[0]);
				});
			}
			setProducts(products);
        }
    }, [elevateMarket, cart]);

	async function onClick(product) {
		await elevateClickNotification(
			product.ticket,
			elevateMarket,
			language.languageCode,
			isMobile ? 'mobile' : 'desktop'
		)
	}

    function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

    function getProductImage(product) {
		let productImages = product.imageInfo?.images;

		if(!productImages || productImages.length <= 0) {
			return { src: '/images/placeholder/product.jpg', width: 550, height: 510 };
		}

		let imageSource = productImages[0].sources[0];

		if(productImages.length > 1) {
			productImages.forEach((image) => {
				if (image['sources'][0].width === 550) {
					imageSource = image['sources'][0];
				}
			});
		}

		return {
			src: imageSource.url,
			width: imageSource.width || 550,
			height: imageSource.height || 510
		}
	}

	function getProductVariants(product) {
		let allVariants = product.variants.filter((variant) => variant.label).map((variant) => {
			return variant.label;
		});

		return allVariants.length > 0;
	}

	function getMainVariant(product) {
		let mainSku = product.key.split('-')[1];
		let mainVariant = product.variants.find((variant) => {
			return variant.key === mainSku;
		});

		return mainVariant || product.variants[0];
	}

	function getUUID(product) {
		return product.custom.uuid ? product.custom.uuid[0].id : 'abcdefghijkl';
	}

    function renderProduct(product, key) {
		let mainVariant = getMainVariant(product);
		let mainImage = getProductImage(product);

		return <ProductItem
			id={getUUID(mainVariant)}
			productNumber={mainVariant.key}
			name={product.title}
			manufacturer={product.brand}
			link={urlNormalize(product.link)}
			prices={{
				price: mainVariant.sellingPrice,
				listPrice: mainVariant.listPrice,
				isOnSale: mainVariant.sellingPrice < mainVariant.listPrice,
				discount: mainVariant.sellingPrice < mainVariant.listPrice ? {
					amount: mainVariant.sellingPrice - mainVariant.listPrice,
					percentage: (1 - (mainVariant.sellingPrice / mainVariant.listPrice)) * 100
				} : undefined
			}}
			customClasses={{
				link: classes.itemLink,
				imageWrapper: classes.itemImageWrapper,
				info: classes.itemInfo,
				name: classes.itemName,
				price: classes.itemPrice,
				purchaseButton: classes.itemPurchaseButton,
				discountBubble: classes.itemDiscountBubble
			}}
			image={<Image {...mainImage} alt={product.title} unoptimized/>}
			list="Cart Upsell"
			variants={getProductVariants(product) ? mainVariant.label : false}
			available={product.inStock}
			clickCallback={onClick.bind(this, product)}
			elevateTicket={product.ticket}
			isMobile={isMobile}
			addToCart={'minimal'}
			like={false}
			lazyHydrate={false}
			sizes={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}}
			position={key + 1}
			key={key}
		/>
	}

    return (
        elevateMarket && products.length > 0 ?
        <div className={classes.wrapper}>
            <Container disableGutters>
                <Typography className={classes.title}>{t('upsell-title')}</Typography>
                <Grid
                    container
                    justify="flex-start"
			        alignItems="stretch"
			        spacing={!isDesktop ? 1 : 2}
                >
                    {products.map((product, key) => renderProduct(product, key))}
                </Grid>
            </Container>
        </div> : ''
    );
};

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import Like from '../../../public/images/icons/heart-ultrathin.svg';
import { useTranslation } from '../../../tools/i18n';
import theme from '../../theme';
import useUserStore from '../../../services/stores/UserStore';

const useStyles = makeStyles(() => ({
    likeButton: {
        display: 'flex',
        position: 'absolute',
        right: 42,
        bottom: 8,
        zIndex: 20,
        marginTop: 20,
        padding: 10,
        minWidth: 0,
        fontSize: 11,
        color: '#000',
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: 'transparent',
        '& svg': {
            color: '#000',
            fill: 'transparent',
            strokeWidth: 0.25,
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&.active svg': {
            color: '#000',
            fill: '#000',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            '& svg': {
                fill: '#000',
            },
        },
        '&.light': {
            right: 42,
            backgroundColor: 'transparent',
            '& svg': {
                color: '#fff',
                fill: 'transparent',
                strokeWidth: 0.75,
                filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.9))',
            },
            '&.active svg': {
                color: '#fff',
                fill: '#fff',
            },
            '&:hover': {
                backgroundColor: 'transparent',
                '& svg': {
                    fill: '#fff',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: 8,
            right: 45,
        },
    },
    likeLoader: {
        position: 'absolute',
        top: 2,
        left: 2,
    },
    purchaseTouchRipple: {},
}), { name: 'LikeButton' });

export default function LikeButton(
    {
        show,
        productId,
        hasLightButtons,
    },
) {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useUserStore();
    const highlighted = user.isHighlighted(productId);

    if (!show) {
        return null;
    }

    return <>
        <Button
            className={[ classes.likeButton, hasLightButtons, highlighted ? 'active' : '', 'like' ].join(' ')}
            TouchRippleProps={{ classes: { rippleVisible: classes.purchaseTouchRipple } }}
            onClick={(event) => {
                event.preventDefault();
                event.nativeEvent.preventDefault();

                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();

                if (highlighted) {
                    user.removeFavorite(productId);
                } else {
                    user.addFavorite(productId);
                }
            }}>
            <Like width={18} height={18} alt={t('buy-short')} />
        </Button>
    </>;
}

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { AcUnitSharp, Close } from '@material-ui/icons';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import theme from '../theme';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        position: 'fixed',
        bottom: 25,
        left: 25
    },
    iconButton: {
        backgroundColor: '#f0f0f0'
    },
    close: {
        position: 'absolute',
        zIndex: 10,
        top: -10,
        left: -10,
        width: 20,
        height: 20,
        padding: 1,
        backgroundColor: theme.palette.primary.color,
        color: theme.palette.primary.background,
        borderRadius: 3,
        cursor: 'pointer'
    },
    toolPopup: {
        position: 'absolute',
        bottom: '125%',
        left: 0,
        padding: 15,
        backgroundColor: '#f0f0f0',
        boxShadow: theme.boxShadow[0]
    },
    clearButton: {
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.primary.color,
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#444444'
        }
    }
}, { name: 'InternalTool' });

export default function InternalTool() {
    const classes = useStyles();
    const router = useRouter();
    const [ enable, setEnabled ] = useState(false);
    const [ show, setShow ] = useState(false);
    const allowedIpAddresses = [
        '62.181.237.166',
        '192.168.55.',
        '2a01:2b0:30c5:8888'
    ];

    useEffect(async () => {
        let cookieVal = Cookies.get('InternalTools');

        if ((cookieVal === 'true' && cookieVal !== 'undefined') || router.asPath.indexOf('?internalTool') >= 0) {
            let getIp = await fetch('/api/internal/getIp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            let ipJson = await getIp.json();
            let ip = ipJson.ip.split(',')[0];

            allowedIpAddresses.forEach((allowedIp) => {
                if (ip === '::1' || ip.indexOf(allowedIp) >= 0) {
                    Cookies.set('InternalTools', true);
                    setEnabled(true);
                } else {
                    Cookies.set('InternalTools', false);
                    setEnabled(false);
                }
            });
        }
    }, []);

    function generateUrl(base, timestamp, slugs) {
        // Create the path by joining slugs for the URL
        const path = slugs.join('/');
        debugger;
        // Create query parameters using slugs
        const queryParams = slugs.map(slug => `slug=${slug}`).join('&');

        // Append the version query param
        const fullQuery = `${queryParams}&__v__=${timestamp}`;

        // Generate the final URL
        return `${base}/_next/data/${timestamp}/${path}.json?${fullQuery}`;
    }

    async function clearCache() {
        let buildId = window?.__NEXT_DATA__?.buildId;
        let location = router.basePath.replace(/\/$/, '');
        let url = router.asPath !== '/' ? location + router.asPath : location;
        let apiUrl = location + '/api' + (router.asPath !== '/' ? router.asPath : '');
        let nextServerSidePropsUrl = generateUrl(
            location,
            buildId,
            (router.asPath !== '/' ? router.asPath : '').split('/').filter((item) => item.trim())
        );

        url = url.replace('?internalTool', '');
        apiUrl = apiUrl.replace('?internalTool', '');
        nextServerSidePropsUrl = nextServerSidePropsUrl.replace('?internalTool', '');

        if (window?.__NEXT_DATA__?.buildId) {
            apiUrl = apiUrl + '?__v__=' + window?.__NEXT_DATA__?.buildId;
        }

        let www2Url = url.replace('www', 'www2');
        let www2ApiUrl = apiUrl.replace('www', 'www2');

        await fetch('https://interstellar' + '.newport.' + 'se/api/utility/' + 'cache-manager/web/purge-by-urls', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                urls: [ url, apiUrl, nextServerSidePropsUrl, www2Url, www2ApiUrl ]
            })
        });
    }

    function disableButton() {
        setEnabled(false);
        Cookies.remove('InternalTools');
    }

    return (
        <>
            {enable ? <div className={classes.wrapper}>
                <Close className={classes.close} onClick={() => disableButton()}/>
                <Button className={classes.iconButton} onClick={() => setShow(!show)}><AcUnitSharp fontSize="large"
                                                                                                   color="primary"/></Button>
                {show ? <div className={classes.toolPopup}>
                    <Button className={classes.clearButton} onClick={() => clearCache()}>Clear cache</Button>
                </div> : ''}
            </div> : ''}
        </>
    );
}

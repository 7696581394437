import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import StorefrontApiService from '@services/StorefrontApiService';
import fetch from 'react-storefront/fetch';
import debounce from 'lodash/debounce';

const expressShippingSkus = {
    '9941': '3b41d892fd584e4b9c2259ba40922328',
    '9942': '36663c2aac7c4896acdccc02a9ffddcc',
};

const handleErrors = (result, type, message) => {
    console.error(result);
    return `${message} [${type}]`;
}

const useCartStore = create(
    persist(
        (set, get) => {
            const changeShippingDebounced = debounce(async () => {
                const { getShippingMethods, setShippingMethod, setLoadingShipping } = get().methods;
                setLoadingShipping(true);
                await getShippingMethods();

                let shippingMethodExists = get().shippingMethods.filter((shippingMethod) => {
                    return shippingMethod.id === get().methods.getShippingMethodId();
                });

                if (shippingMethodExists.length === 0 && get().shippingMethods.length > 0) {
                    await setShippingMethod(get().shippingMethods[0].id);
                    await get().methods.getCart();
                }
                setLoadingShipping(false);
                console.log('changed method', shippingMethodExists.length === 0);
            }, 250);

            const setShippingMethodDebounced = debounce(async (shippingMethodId) => {
                const { setLoadingShipping } = get().methods;
                let getSetShippingMethodsEndpoint = '/api/checkout/set-shipping-method';

                setLoadingShipping(true);

                try {
                    let requestData = await fetch(getSetShippingMethodsEndpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            shippingMethodId,
                        }),
                    });

                    let shippingMethodsData = await requestData.json();

                    if (shippingMethodsData.status !== 'success') {
                        console.log('Set shipping method error');
                    }

                    if (shippingMethodsData.status === 'success') {
                        console.log('Set shipping method success');
                        await get().methods.getCart();
                    }

                    setLoadingShipping(false);
                } catch (e) {
                    console.log(e);
                    setLoadingShipping(false);
                }
            }, 250);

            return ({
                loadingShipping: false,
                loading: false,
                token: null,
                cart: {
                    extensions: {},
                    lineItems: [],
                    errors: {},
                    price: {
                        totalPrice: 0,
                    },
                },
                specials: {
                    preparedCoupon: null,
                    shippingMethodId: null,
                },
                shippingMethods: null,
                methods: {
                    setLoadingShipping(loading) {
                        console.log('setLoading shipping', loading);
                        set({ loadingShipping: loading });
                    },
                    setCart(cart) {
                        set((state) => {
                                const { changeShippingMethodIfUnavailable } = get().methods;

                                const keys = Object.keys(cart.errors);
                                if (keys.filter((key) => key.includes('shipping-method-blocked')).length) {
                                    changeShippingMethodIfUnavailable();
                                    return {};
                                }

                                return ({ cart });
                            },
                        );
                    },
                    setPreparedCoupon(coupon) {
                        set((state) => ({
                            specials: {
                                ...state.specials,
                                prepareCoupon: coupon,
                            },
                        }));
                    },
                    setToken(token) {
                        set({ token });
                        this.refresh();
                    },
                    refresh() {
                        const { getCart } = get().methods;
                        getCart();
                    },
                    setIsLoading(bool = false, logMessage = 'no message') {
                        console.log('set loading cart:', logMessage, bool, 'shipping state:', get().loadingShipping);
                        set({ loading: bool });
                    },
                    async getCart(isCheckout = false, keepLoading = false) {
                        const { setIsLoading, setCart } = get().methods;
                        let headers = {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        };

                        if (isCheckout) {
                            headers['sw-is-checkout'] = true;
                        }

                        setIsLoading(true, 'running getCart');

                        const response = await fetch('/api/cart/get', {
                            method: 'get',
                            headers: headers,
                            credentials: 'include',
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            setCart(result.cart);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                            throw new Error(
                                handleErrors(
                                    result,
                                    'error',
                                    'An unknown error occurred while attempting to fetch your cart.',
                                ),
                            );
                        }

                        console.log('ended getCart');
                    },
                    async applyCoupon(code, email = '') {
                        const {
                            setCart,
                            trackAddedCouponCodes,
                            saveCouponCodesWhenEmpty,
                            setPreparedCoupon,
                        } = get().methods;
                        const response = await fetch('/api/cart/applyCoupon', {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                code: code.toUpperCase(),
                                email: email,
                            }),
                        });

                        const result = await response.json();
                        if (result.status === 'success') {
                            let errorKeys = Object.keys(result.cart.errors);
                            const preparedCoupon = get().specials.preparedCoupon;

                            if (result.cart.errors && errorKeys.indexOf('promotion-not-found') >= 0) {
                                if (preparedCoupon === code) {
                                    setPreparedCoupon(false);
                                    return result.cart.errors;
                                }
                            }

                            if (result.cart) {
                                if (result?.cart?.lineItems.length === 0) {
                                    saveCouponCodesWhenEmpty(code);
                                }

                                setCart(result.cart);
                                trackAddedCouponCodes(result.cart).then(() => null);

                                return result.cart.errors;
                            }
                        } else {
                            if (result.status === 'missing-email') {
                                return {
                                    'email-required': {
                                        key: 'email-required',
                                        level: 5,
                                    },
                                };
                            } else if (result.status === 'promotion-not-found' || result.status === 'promotion-redeemed') {
                                return result.cart.errors;
                            } else {
                                throw new Error(
                                    handleErrors(
                                        result,
                                        'error',
                                        'An unknown error occurred while attempting to apply coupon code.',
                                    ),
                                );
                            }
                        }
                    },
                    getAddedProductNumber(productId) {
                        let addedProductNumber = '';
                        get().cart.lineItems.forEach((item) => {
                            if (item.referencedId === productId) {
                                let productNumber = item.payload.productNumber;
                                addedProductNumber = productNumber;
                            }
                        });

                        return addedProductNumber;
                    },
                    getAppliedCouponCodes() {
                        return get().cart?.extensions?.['cart-promotions']?.addedCodes;
                    },
                    async addToCart({ product, quantity }) {
                        const {
                            productExistInCart,
                            updateCart,
                            getAddedProductNumber,
                            setCart,
                            setIsLoading,
                        } = get().methods;
                        let cartItem = await productExistInCart(product);

                        if (cartItem) {
                            await updateCart({ item: cartItem.id, quantity: cartItem.quantity + quantity });

                            let productNumber = getAddedProductNumber(get().cart, product);

                            // Trigger event listeners to show header and open cart
                        } else {
                            setIsLoading(true, 'running addToCart');

                            const response = await fetch('/api/cart/add', {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                credentials: 'include',
                                body: JSON.stringify({
                                    product,
                                    quantity: parseInt(quantity),
                                }),
                            });

                            const result = await response.json();

                            if (result.status === 'success') {
                                setCart(result.cart);

                                let productNumber = getAddedProductNumber(result.cart, product);

                                // Trigger event listeners to show header and open cart
                                document.dispatchEvent(new CustomEvent('add-to-cart', {
                                    detail: productNumber,
                                }));

                                setIsLoading(false);
                            } else {
                                setIsLoading(false);
                                throw new Error(
                                    handleErrors(
                                        result,
                                        'error',
                                        'An unknown error occurred while attempting to add the item to your cart.',
                                    ),
                                );
                            }
                        }
                    },
                    async addItemsToCart(productIds) {
                        const { setCart, getCart, setIsLoading } = get().methods;
                        const api = new StorefrontApiService();

                        await getCart();
                        const existingIds = get().cart.lineItems.map((item) => item.referencedId);
                        const ids = productIds?.filter((id) => {
                            return !existingIds.includes(id);
                        });

                        setIsLoading(true, 'running addItemsToCart');

                        if (ids.length >= 1) {
                            document.dispatchEvent(new CustomEvent('add-to-cart', {}));

                            try {
                                const response = await api.addItemsToCart(ids);

                                if (response.status === 'success' && response?.cart) {
                                    setCart(response.cart);
                                }

                            } catch (e) {
                                console.error('[CartFailure]', e);
                            }
                        }

                        setIsLoading(false);
                    },
                    async productExistInCart(product) {
                        let productExist = false;

                        get().cart.lineItems.forEach((item) => {
                            if (item.referencedId === product) {
                                productExist = item;
                            }
                        });

                        return productExist;
                    },
                    getCartDiscount() {
                        let items = get().cart.lineItems;

                        console.log(get());

                        if (!items?.length) {
                            return 0;
                        }

                        let discountTotal = 0;
                        items.forEach((item) => {
                            const price = item.price;
                            const qty = item.quantity;

                            if (price.listPrice) {
                                discountTotal += qty * Math.abs(price.listPrice.discount);
                            }
                        });

                        return discountTotal;
                    },
                    getCartQuantity() {
                        let quantity = 0;

                        get().cart.lineItems.forEach((item) => {
                            if (item.type === 'product' && Object.keys(expressShippingSkus).indexOf(item.payload?.productNumber) < 0) {
                                quantity += item.quantity;
                            }
                        });

                        return quantity;
                    },
                    async applyGiftcard(code) {
                        const { getCart } = get().methods;
                        const response = await fetch('/api/cart/applyGiftcard', {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                code: code,
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            await getCart();
                            return result.data;
                        } else {
                            return result.data;
                        }
                    },
                    async removeGiftcard(code) {
                        const { getCart } = get().methods;
                        const response = await fetch('/api/cart/applyGiftcard', {
                            method: 'delete',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                code: code,
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            await getCart();
                            return result.data;
                        } else {
                            return result.data;
                        }
                    },
                    async updateCart({ item, quantity, isCheckout = false }) {
                        const { setIsLoading, setCart } = get().methods;
                        let headers = {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        };

                        if (isCheckout) {
                            headers['sw-is-checkout'] = true;
                        }

                        setIsLoading(true, 'running updateCart');

                        const response = await fetch('/api/cart/update', {
                            method: 'post',
                            headers: headers,
                            credentials: 'include',
                            body: JSON.stringify({
                                item,
                                quantity: parseInt(quantity),
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            setCart(result.cart);
                            setIsLoading(false);

                            // Trigger event listeners to track cart updates
                            document.dispatchEvent(new CustomEvent('has-updated-cart'));
                        } else {
                            setIsLoading(false);
                            throw new Error(
                                handleErrors(
                                    result,
                                    'error',
                                    'An unknown error occurred while attempting to add the item to your cart.',
                                ),
                            );
                        }
                    },
                    async removeCartItem({ item, isCheckout = false, code = false }) {
                        const {
                            setIsLoading,
                            setPreparedCoupon,
                            setCart,
                            trackAddedCouponCodes,
                        } = get().methods;
                        let headers = {
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache',
                            'Expires': '0',
                        };

                        if (isCheckout) {
                            headers['sw-is-checkout'] = true;
                        }

                        setIsLoading(true, 'running removeCartItem');

                        const response = await fetch('/api/cart/remove', {
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                item,
                            }),
                        });

                        const result = await response.json();

                        if (result.status === 'success') {
                            if (code && get().specials.preparedCoupon === code) {
                                setPreparedCoupon(null);
                            }

                            setCart(result.cart);
                            setIsLoading(false);

                            if (code) {
                                trackAddedCouponCodes(result.cart).then(() => null);
                            }

                            setIsLoading(false);

                            // Trigger event listeners to track cart item removals
                            document.dispatchEvent(new CustomEvent('remove-from-cart'));
                        } else {
                            setIsLoading(false);
                            throw new Error(
                                handleErrors(
                                    result,
                                    'error',
                                    'An unknown error occurred while attempting to add the item to your cart.',
                                ),
                            );
                        }
                    },
                    async trackAddedCouponCodes(cart) {
                        const { getAppliedCouponCodes } = get().methods;
                        let appliedCoupons = getAppliedCouponCodes(cart);

                        await fetch('/api/tracking/custom-fields', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            credentials: 'include',
                            body: JSON.stringify({ customFields: { applied_coupons: appliedCoupons } }),
                        });
                    },
                    saveCouponCodesWhenEmpty(couponCode) {
                        get().methods.setPreparedCoupon(couponCode);
                    },
                    getItemInCart(id) {
                        let foundItem = false;
                        get().cart.lineItems.forEach((item) => {
                            if (item.id === id) {
                                foundItem = item;
                            }
                        });

                        return foundItem;
                    },
                    async getShippingMethods() {
                        let getShippingMethodsEndpoint = '/api/checkout/shipping-methods';

                        try {
                            let requestData = await fetch(getShippingMethodsEndpoint, {
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });

                            let shippingMethodsData = await requestData.json();

                            shippingMethodsData.sort((a, b) => {
                                if (a.position > b.position) {
                                    return 1;
                                } else if (a.position < b.position) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            });

                            set({ shippingMethods: shippingMethodsData });
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    async setShippingMethod(shippingMethodId) {
                        setShippingMethodDebounced(shippingMethodId);
                    },
                    getShippingMethodId() {
                        return get().methods.getCurrentShippingMethod()?.id;
                    },
                    getCurrentShippingMethod() {
                        return get().cart?.deliveries?.[0]?.shippingMethod;
                    },
                    async changeShippingMethodIfUnavailable() {
                        changeShippingDebounced();
                    },
                },
            });
        },
        {
            name: 'cart-storage',
            partialize: (state) => ({
                token: state.token,
                shipping: state.shipping,
                cart: state.cart,
                specials: state.specials,
            }),
        },
    ),
);

export default useCartStore;

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import StorefrontApiService from '@services/StorefrontApiService';

const api = new StorefrontApiService();

const useApplicationStore = create(
    persist(
        (set, get) => ({
            marketingLabels: {},
            methods: {
                async warmupStaticProperties() {
                    const labels = await api.fetchMarketingLabels();
                    set({ marketingLabels: labels.marketingLabels });
                },
                getMarketingLabels(propertyIds) {
                    const labels = get().marketingLabels;
                    return propertyIds?.filter((id) => labels?.[id]).map((id) => {
                        return labels[id];
                    }) || [];
                },
            },
        }),
        {
            name: 'app-storage',
            partialize: (state) => ({
                marketingLabels: state.marketingLabels,
            }),
        },
    ),
);

export default useApplicationStore;

import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import theme from "../../../theme";
import {getCMSConfig, getCMSSettings, getCMSSlot} from "tools/cmsBlockSettings";
import BannerContent from "../text/banner-content";
import Image from 'next/image';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "next/link";
import urlNormalizer from "tools/urlNormalization";
import {useRouter} from "next/router";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles({
	wrapper: {
		padding: 0,
		overflow: 'hidden',
	},
	grid: {
		[theme.breakpoints.down('xs')]: {
			marginBottom: '0px !important'
		}
	},
	item: {
		[theme.breakpoints.down('xs')]: {
			paddingTop: '0 !important',
			paddingBottom: '15px !important'
		},
		[theme.breakpoints.up('md')]: {
			margin: '-5px 0'
		},
		[theme.breakpoints.up('sm')]: {
			'.wedding-double-content &:first-of-type $content': {
				justifyContent: 'flex-end'
			},
			'.wedding-double-content &:last-of-type $content': {
				justifyContent: 'flex-start'
			}
		}
	},
	link: {
		position: 'relative',
		background: theme.palette.primary.color,
		display: 'block',
		'.wedding-double-content &': {
			backgroundColor: 'transparent'
		}
	},
	imageWrapper: {
		transition: 'opacity 0.25s ease',
		'&:hover': {
			opacity: '0.85 !important'
		},
		'.wedding-border &:before, .wedding-border &:after': {
			content: '""',
			position: 'absolute',
			top: 6,
			left: 6,
			right: 6,
			bottom: 6,
			zIndex: 5,
			pointerEvents: 'none'
		},
		'.wedding-border &:before': {
			border: '3px solid white'
		},
		'.wedding-border &:after': {
			top: 12,
			left: 12,
			right: 12,
			bottom: 12,
			border: '1px solid white'
		},
		'.wedding-double-content &:hover': {
			opacity: '0 !important'
		}
	},
	content: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        padding: '0 40px 40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        pointerEvents: 'none',
		'&:before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 1,
            width: '100%',
            height: '33%',
            backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.0))'
        },
		'.wedding-border &:before, .wedding-double-content &:before': {
			display: 'none'
		},
		'.center-content &': {
			alignItems: 'center'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 20px 30px',
		}
	},
	saleIndicator: {
		position: 'absolute',
		top: 20,
		right: 20,
		width: 60,
		height: 60,
		zIndex: 10,
		display: 'none',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		color: theme.palette.primary.background,
		fontSize: 13,
		letterSpacing: 1,
		backgroundColor: theme.palette.primary.color
	}
}, {name: 'NewportBarkerTwo'});

export default function NewportBarkerTwo({block, slots, category}) {
	const router = useRouter();
	const config = useSessionStore((state) => state.session.config);
	const classes = useStyles();
	const CMSSettings = getCMSSettings(block);
	const isIPad = useMediaQuery(theme.breakpoints.down('lg'));
	const leftText = getCMSSlot('left-overlay', slots);
	const leftImage = getCMSSlot('left', slots);
	const rightText = getCMSSlot('right-overlay', slots);
	const rightImage = getCMSSlot('right', slots);

	function getBannerLink(slot) {
		return slot.data.url || '#';
	}

	function handleMyNewportLinks(content) {
		if(content && config.myNewport) {
			return content.replaceAll('|myNewport|', config.myNewport);
		}

		return content;
	}

	function getImage(slot) {
		let media = slot.data.media;

		let returnObject = {
			src: media.url
		};

		returnObject.layout = 'responsive';
		returnObject.width = media.metaData.width;
		returnObject.height = media.metaData.height;

		return returnObject;
	}

	function getOpacity(image) {
		if(image?.translated?.config?.opacity?.value === '0') {
			return 0;
		}

		return 1;
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function renderSaleIndicator() {
		const allowedLanguages = ['sv', 'no', 'fi'];
        const language = config.language.locale;

		return allowedLanguages.indexOf(language) >= 0 ? <div className={[classes.saleIndicator, 'saleIndicator'].join(' ')}>SALE</div> : '';
    }

	return (
		<div className={classes.wrapper}>
			<Grid className={[classes.grid, CMSSettings.class].join(' ')} spacing={isIPad ? 2 : 1} style={{
				...CMSSettings.styles,
				width: 'auto'
			}} container>
				<Grid xs={12} md={6} className={classes.item} item>
					<div className={classes.wrapper}>
						<Link href={urlNormalize(handleMyNewportLinks(getBannerLink(leftImage)))}>
							<a className={classes.link}>
								{renderSaleIndicator()}
								<div className={classes.imageWrapper} style={{opacity: getOpacity(leftImage)}}>
									<Image {...getImage(leftImage)} alt={category.metaTitle || category.name || 'Newport image'} className={[classes.image, 'image'].join(' ')} unoptimized/>
								</div>
								<div className={[classes.content].join(' ')}>
									<BannerContent content={leftText.data.content}/>
								</div>
							</a>
						</Link>
					</div>
				</Grid>
				<Grid xs={12} md={6} className={classes.item} item>
					<div className={classes.wrapper}>
						<Link href={urlNormalize(handleMyNewportLinks(getBannerLink(rightImage)))}>
							<a className={classes.link}>
								{renderSaleIndicator()}
								<div className={classes.imageWrapper} style={{opacity: getOpacity(rightImage)}}>
									<Image {...getImage(rightImage)} alt={category.metaTitle || category.name || 'Newport image'} className={[classes.image, 'image'].join(' ')} unoptimized/>
								</div>
								<div className={classes.content}>
									<BannerContent content={rightText.data.content}/>
								</div>
							</a>
						</Link>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
